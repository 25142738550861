import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import logoLight from "../assets/images/logo-light.webp";
import { useAuth } from "../AuthContext";
import axios from "axios";

export default function Navbar() {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [scroll, setScroll] = useState(false);
  const { user, logout } = useAuth();
  const [trainUrl, setTrainUrl] = useState("");

  useEffect(() => {
    if (user) {
      const user_url = "https://api-turkgpt.onrender.com";
      axios.post(user_url + "/train_url?token="+user.token).then((res) => {
        setTrainUrl(res.data.url);
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    activateMenu();
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 50);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  function getClosest(elem, selector) {
    // Element.matches() polyfill
    if (!Element.prototype.matches) {
      Element.prototype.matches =
        Element.prototype.matchesSelector ||
        Element.prototype.mozMatchesSelector ||
        Element.prototype.msMatchesSelector ||
        Element.prototype.oMatchesSelector ||
        Element.prototype.webkitMatchesSelector ||
        function (s) {
          var matches = (this.document || this.ownerDocument).querySelectorAll(
              s
            ),
            i = matches.length;
          while (--i >= 0 && matches.item(i) !== this) {}
          return i > -1;
        };
    }
    for (; elem && elem !== document; elem = elem.parentNode) {
      if (elem.matches(selector)) return elem;
    }
    return null;
  }

  function activateMenu() {
    var menuItems = document.getElementsByClassName("sub-menu-item");
    if (menuItems) {
      var matchingMenuItem = null;
      for (var idx = 0; idx < menuItems.length; idx++) {
        if (menuItems[idx].href === window.location.href) {
          matchingMenuItem = menuItems[idx];
        }
      }

      if (matchingMenuItem) {
        matchingMenuItem.classList.add("active");

        var immediateParent = getClosest(matchingMenuItem, "li");

        if (immediateParent) {
          immediateParent.classList.add("active");
        }

        var parent = getClosest(immediateParent, ".child-menu-item");
        if (parent) {
          parent.classList.add("active");
        }

        // eslint-disable-next-line no-redeclare
        var parent = getClosest(parent || immediateParent, ".parent-menu-item");

        if (parent) {
          parent.classList.add("active");

          var parentMenuitem = parent.querySelector(".menu-item");
          if (parentMenuitem) {
            parentMenuitem.classList.add("active");
          }

          var parentOfParent = getClosest(parent, ".parent-parent-menu-item");
          if (parentOfParent) {
            parentOfParent.classList.add("active");
          }
        } else {
          // eslint-disable-next-line no-redeclare
          var parentOfParent = getClosest(
            matchingMenuItem,
            ".parent-parent-menu-item"
          );
          if (parentOfParent) {
            parentOfParent.classList.add("active");
          }
        }
      }
    }
  }

  return (
    <>
      <nav
        id="topnav"
        className={`${scroll ? "nav-sticky" : ""} defaultscroll is-sticky`}
      >
        <div className="container">
          <Link className="logo" to="/">
            <img
              src={logoLight}
              className="h-16 hidden dark:inline-block"
              alt=""
            />
          </Link>

          <div className="menu-extras">
            <div className="menu-item">
              <Link
                className={`${toggleMenu ? "open" : ""} navbar-toggle`}
                onClick={() => setToggleMenu(!toggleMenu)}
              >
                <div className="lines">
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </Link>
            </div>
          </div>
          {!user && (
            <ul className="buy-button list-none mb-0">
              <li className="inline mb-0">
                <Link to="/login">
                  <span className="py-[6px] px-4 md:inline hidden items-center justify-center tracking-wider align-middle duration-500 text-sm text-center rounded bg-amber-400/5 hover:bg-amber-400 border border-amber-400/10 hover:border-amber-400 text-amber-400 hover:text-white font-semibold">
                    Giriş Yap
                  </span>
                </Link>
              </li>

              <li className="md:inline hidden ps-1 mb-0 ">
                <Link to="/signup">
                  <span className="py-[6px] px-4 md:inline items-center justify-center tracking-wider align-middle duration-500 text-sm text-center rounded bg-amber-400 hover:bg-amber-500 border border-amber-400 hover:border-amber-500 text-white font-semibold">
                    Kayıt Ol
                  </span>
                </Link>
              </li>
            </ul>
          )}

          {user && (
            <ul className="buy-button list-none mb-0">
              {user.username === "admin" && <li className="inline mb-0">
                <Link to={trainUrl}>
                  <span className="py-[6px] px-4 md:inline hidden items-center justify-center tracking-wider align-middle duration-500 text-sm text-center rounded bg-amber-400/5 hover:bg-amber-400 border border-amber-400/10 hover:border-amber-400 text-amber-400 hover:text-white font-semibold">
                    Train
                  </span>
                </Link>
              </li>}

              <li className="md:inline hidden ps-1 mb-0 ">
                <Link to="/">
                  <span
                    className="py-[6px] px-4 md:inline items-center justify-center tracking-wider align-middle duration-500 text-sm text-center rounded bg-amber-400 hover:bg-amber-500 border border-amber-400 hover:border-amber-500 text-white font-semibold"
                    onClick={logout}
                  >
                    Çıkış Yap
                  </span>
                </Link>
              </li>
            </ul>
          )}
          <div id="navigation" className={`${toggleMenu ? 'block' : ''}`}>
            <ul className="navigation-menu">
              <li className="has-submenu parent-menu-item">
                <Link to="/">Ana Sayfa</Link>
              </li>

              <li>
                <Link to="/aboutus" className="sub-menu-item">
                  Hakkımızda
                </Link>
              </li>
              <li>
                <Link to="/contact" className="sub-menu-item">
                  İletişim
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}
