import React from "react";
import { Link } from "react-router-dom";
import logo from "../assets/images/logo-light.png";

export default function ResetPassword() {
  return (
    <>
      <section className="relative overflow-hidden h-screen flex items-center bg-page bg-no-repeat bg-left bg-cover bg-fixed">
        <div className="absolute inset-0 bg-slate-950/20"></div>
        <div className="container relative">
          <div className="md:flex justify-center">
            <div className="lg:w-2/3 md:w-2/4">
              <div className="rounded-lg shadow bg-white dark:bg-slate-900 p-12">
                <Link to="/">
                  <img src={logo} alt="" className="inline-block h-16" />
                  <span className="ml-7 text-xl font-semibold">
                    Şifreni Yenile
                  </span>
                </Link>

                <form className="text-start mt-4">
                  <div className="grid grid-cols-1">
                    <div className="mb-4">
                      <label className="font-semibold" htmlFor="LoginEmail">
                        E-Mail Adresi
                      </label>
                      <input
                        id="LoginEmail"
                        type="email"
                        className="form-input mt-3 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-amber-400 dark:border-gray-800 dark:focus:border-amber-400 focus:ring-0"
                        placeholder="name@company.com"
                      />
                    </div>
                    <p className="text-slate-400 mt-2 mb-2 text-sm">
                      <i>
                        Şifrenizi yenilemek için size bir e-posta göndereceğiz.
                      </i>
                      <br />
                    </p>

                    <div className="mb-4">
                      <input
                        type="submit"
                        className="py-2 px-5 inline-block tracking-wide border align-middle duration-500 text-base text-center bg-amber-400 hover:bg-amber-500 border-amber-400 hover:border-amberbg-amber-500 text-white rounded-md w-full"
                        value="Yenile"
                      />
                    </div>

                    <div className="text-center">
                      <span className="text-slate-400 me-2">
                        Şifrenizi Hatırladınız Mı?{" "}
                      </span>{" "}
                      <Link
                        to="/login"
                        className="text-slate-900 dark:text-white font-bold inline-block"
                      >
                        Giriş Yap
                      </Link>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
