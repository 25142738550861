import React, { useState } from "react";
import { Link } from "react-router-dom";

import image1 from "../assets/images/banner.png";
import image2 from "../assets/images/banner1.png";
import image3 from "../assets/images/banner2.png";

import Footer from "../components/footer";

import { FiCheckCircle } from "../assets/icons/vander";

import LogoLight from "../assets/images/logo-light.webp";
import "../../node_modules/react-modal-video/css/modal-video.css";
import Navbar from "../components/navbar";

export default function AboutUs() {
  const [activeIndex, setActiveIndex] = useState(0);
  const teamData = [
    {
      image: "https://avatars.githubusercontent.com/u/47576905?v=4",
      name: "Ömer Bayramçavuş",
      title: "Co-founder",
      link: "https://www.linkedin.com/in/omerbayramcavus/",
    },
    {
      image: "https://avatars.githubusercontent.com/u/53279638?v=4",
      name: "Seda N. Taşkan",
      title: "Co-founder",
      link: "https://www.linkedin.com/in/seda-n-taskan/",
    },
  ];
  return (
    <>
      <Navbar />
      <section className="relative md:py-44 py-32 bg-page bg-no-repeat bg-bottom bg-cover">
        <div className="absolute inset-0 bg-gradient-to-t from-slate-900 to-slate-900/70"></div>
        <div className="container relative">
          <div className="grid grid-cols-1 text-center mt-6">
            <div>
              <h5 className="md:text-4xl text-3xl md:leading-normal leading-normal tracking-wider font-semibold text-white mb-0">
                Hakkımızda
              </h5>
            </div>

            <ul className="tracking-[0.5px] mb-0 inline-block mt-5">
              <li className="inline-block capitalize text-[15px] font-medium duration-500 ease-in-out text-white/50 hover:text-white">
                <Link to="/">Turk-GPT</Link>
              </li>
              <li className="inline-block text-base text-white/50 mx-0.5 ltr:rotate-0 rtl:rotate-180">
                <i className="mdi mdi-chevron-right"></i>
              </li>
              <li
                className="inline-block capitalize text-[15px] font-medium duration-500 ease-in-out text-white"
                aria-current="page"
              >
                Hakkımızda
              </li>
            </ul>
          </div>
        </div>
      </section>
      <div className="relative">
        <div className="shape absolute sm:-bottom-px -bottom-[2px] start-0 end-0 overflow-hidden z-1 text-white dark:text-slate-900">
          <svg
            className="w-full h-auto scale-[2.0] origin-top"
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>

      <section className="relative md:py-24 py-16">
        <div className="container relative">
          <div className="grid md:grid-cols-2 grid-cols-1 items-center gap-6">
            <div className="relative overflow-hidden">
              <div className="relative overflow-hidden shadow-md dark:shadow-gray-800 z-1">
                <img
                  src={LogoLight}
                  alt=""
                  style={{
                    width: "50%",
                    height: "50%",
                    marginLeft: "25%",
                  }}
                />
              </div>
            </div>

            <div className="">
              <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">
                <span className="font-bold">Dokümanlarına,</span> <br /> Hemen
                Eriş
              </h3>
              <p className="text-slate-400 max-w-xl">
                Turk-GPT olarak, yapay zeka ve doğal dil işleme alanında
                çalışmalar yapan bir ekip olarak, sizlere en iyi hizmeti
                sunmaktayız.
              </p>

              <ul className="list-none text-slate-400 mt-4">
                <li className="mb-2 flex items-center">
                  <FiCheckCircle className="text-amber-400 h-5 w-5 me-2" />{" "}
                  Şirketinizin verileri ile çalışın
                </li>
                <li className="mb-2 flex items-center">
                  <FiCheckCircle className="text-amber-400 h-5 w-5 me-2" />{" "}
                  Hızlı ve güvenilir cevaplar alın
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="container relative md:mt-24 mt-16">
          <div className="lg:flex justify-center">
            <div className="lg:w-4/5">
              <ul className="md:flex inline-block w-fit mx-auto flex-wrap justify-center text-center p-2 bg-white dark:bg-slate-900 shadow dark:shadow-gray-800 rounded-md">
                <li
                  role="presentation"
                  className="inline-block md:w-1/3 w-full p-2"
                >
                  <button
                    className={`${
                      activeIndex === 0
                        ? "text-white bg-amber-400"
                        : " hover:text-amber-400 dark:hover:text-white hover:bg-gray-50 dark:hover:bg-slate-800"
                    } px-5 py-3 text-start rounded-md w-full duration-500 `}
                    onClick={() => setActiveIndex(0)}
                  >
                    <h5 className="text-base font-semibold">
                      Kendi Verilerinle Eğit
                    </h5>
                    <p className="text-sm mt-1">
                      Kendi verilerinizle eğitim yaparak, en iyi sonuçları
                      alabilirsiniz.
                    </p>
                  </button>
                </li>
                <li
                  role="presentation"
                  className="inline-block md:w-1/3 w-full p-2"
                >
                  <button
                    className={`${
                      activeIndex === 1
                        ? "text-white bg-amber-400"
                        : " hover:text-amber-400 dark:hover:text-white hover:bg-gray-50 dark:hover:bg-slate-800"
                    } px-5 py-3 text-start rounded-md w-full duration-500 `}
                    onClick={() => setActiveIndex(1)}
                  >
                    <h5 className="text-base font-semibold">
                      Hızlı ve Güvenilir Cevaplar
                    </h5>
                    <p className="text-sm mt-1">
                      Hızlı ve güvenilir cevaplar alarak, işlerinizi
                      hızlandırın.
                    </p>
                  </button>
                </li>
                <li
                  role="presentation"
                  className="inline-block md:w-1/3 w-full p-2"
                >
                  <button
                    className={`${
                      activeIndex === 2
                        ? "text-white bg-amber-400"
                        : " hover:text-amber-400 dark:hover:text-white hover:bg-gray-50 dark:hover:bg-slate-800"
                    } px-5 py-3 text-start rounded-md w-full duration-500 `}
                    onClick={() => setActiveIndex(2)}
                  >
                    <h5 className="text-base font-semibold">
                      Dokümanlarınla İletişime Geç
                    </h5>
                    <p className="text-sm mt-1">
                      RAG Yöntemi ile direkt dokümanlarınızla iletişime geçin.
                    </p>
                  </button>
                </li>
              </ul>

              <div id="StarterContent" className="mt-6">
                {activeIndex === 0 ? (
                  <div>
                    <div className="relative overflow-hidden rounded-lg border border-amber-400/5 bg-gradient-to-tl to-amber-400/30  from-fuchsia-600/30 dark:to-amber-400/50 dark:from-fuchsia-600/50 px-6 pt-6">
                      <img src={image1} className="rounded-t-lg" alt="" />
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {activeIndex === 1 ? (
                  <div>
                    <div className="relative overflow-hidden rounded-lg border border-amber-400/5 bg-gradient-to-tl to-amber-400/30  from-fuchsia-600/30 dark:to-amber-400/50 dark:from-fuchsia-600/50 px-6 pt-6">
                      <img src={image2} className="rounded-t-lg" alt="" />
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {activeIndex === 2 ? (
                  <div>
                    <div className="relative overflow-hidden rounded-lg border border-amber-400/5 bg-gradient-to-tl to-amber-400/30  from-fuchsia-600/30 dark:to-amber-400/50 dark:from-fuchsia-600/50 px-6 pt-6">
                      <img src={image3} className="rounded-t-lg" alt="" />
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="container relative md:mt-24 mt-16">
          <div className="grid grid-cols-1 pb-6 text-center">
            <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">
              Takım
            </h3>

            <p className="text-slate-400 max-w-xl mx-auto">
              Takım arkadaşlarımızın her biri kendi alanında uzman ve deneyimli
              kişilerdir.
            </p>
          </div>

          <div className="grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 mt-6 gap-6">
            {teamData.map((item, index) => {
              return (
                <div
                  className="overflow-hidden relative w-full mx-auto rounded-full bg-white dark:bg-slate-900 shadow hover:shadow-md dark:shadow-slate-800 flex items-center duration-500"
                  key={index}
                  style={{
                    borderTopLeftRadius: "10rem",
                    borderBottomLeftRadius: "10rem",
                  }}
                >
                  <img
                    src={item.image}
                    alt=""
                    className="relative -start-0 w-40 h-40 rounded-full shadow-lg"
                  />
                  <div className="min-w-0 py-10 ps-10 pe-6">
                    <Link
                      to={item.link}
                      className="text-lg font-medium hover:text-amber-400"
                    >
                      {item.name}
                    </Link>
                    <p className="text-slate-400">{item.title}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
