import React from "react";
import { Link } from "react-router-dom";

import contactImg from "../assets/images/contact.svg";

import Navbar from "../components/navbar";
import Footer from "../components/footer";

import { FiHexagon, FiMail, FiMapPin } from "../assets/icons/vander";
import { toast } from "react-toastify";
import axios from "axios";

export default function Contact() {
  const [form, setForm] = React.useState({
    name: "",
    email: "",
    topic: "",
    message: "",
  });
  const [loading, setLoading] = React.useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    axios.post("https://api-turkgpt.onrender.com/add_contact", form).then(
      (res) => {
        toast.success("Mesajınız başarıyla gönderildi.");
        setLoading(false);
        setForm({
          name: "",
          email: "",
          topic: "",
          message: "",
        });
      },
      (err) => {
        toast.error("Bir hata oluştu. Lütfen tekrar deneyin.");
        setLoading(false);
      }
    );
  };

  return (
    <>
      <Navbar />
      <div className="container-fluid relative mt-20">
        <div className="grid grid-cols-1">
          <div className="w-full leading-[0] border-0">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12237.780029289519!2d32.8464813!3d39.9314346!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14d34efa2943c777%3A0x1c83699501e96360!2zR2F6acyHIMOcbmnMh3ZlcnNpzId0ZXNpzIcgTcO8aGVuZGlzbGlrIEZha8O8bHRlc2k!5e0!3m2!1str!2str!4v1711807443386!5m2!1str!2str"
              title="my-map"
              style={{ border: "0" }}
              className="w-full h-[500px] "
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </div>
      <section className="relative lg:py-24 py-16">
        <div className="container">
          <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px]">
            <div className="lg:col-span-7 md:col-span-6">
              <img src={contactImg} alt="" />
            </div>

            <div className="lg:col-span-5 md:col-span-6">
              <div className="lg:ms-5">
                <div className="bg-white dark:bg-slate-900 rounded-md shadow dark:shadow-gray-700 p-6">
                  <h3 className="mb-6 text-2xl leading-normal font-semibold">
                    Bizimle İletişime Geçin!
                  </h3>

                  <form onSubmit={handleSubmit}>
                    <p className="mb-0" id="error-msg"></p>
                    <div id="simple-msg"></div>
                    <div className="grid lg:grid-cols-12 lg:gap-6">
                      <div className="lg:col-span-6 mb-5">
                        <label htmlFor="name" className="font-semibold">
                          İsim:
                        </label>
                        <input
                          name="name"
                          id="name"
                          type="text"
                          className="form-input w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-amber-400 dark:border-gray-800 dark:focus:border-amber-400 focus:ring-0 mt-2"
                          value={form.name}
                          onChange={(e) =>
                            setForm({ ...form, name: e.target.value })
                          }
                        />
                      </div>

                      <div className="lg:col-span-6 mb-5">
                        <label htmlFor="email" className="font-semibold">
                          E-Mail:
                        </label>
                        <input
                          name="email"
                          id="email"
                          type="email"
                          className="form-input w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-amber-400 dark:border-gray-800 dark:focus:border-amber-400 focus:ring-0 mt-2"
                          value={form.email}
                          onChange={(e) =>
                            setForm({ ...form, email: e.target.value })
                          }
                        />
                      </div>
                    </div>

                    <div className="grid grid-cols-1">
                      <div className="mb-5">
                        <label htmlFor="subject" className="font-semibold">
                          Konu:
                        </label>
                        <input
                          name="topic"
                          id="subject"
                          className="form-input w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-amber-400 dark:border-gray-800 dark:focus:border-amber-400 focus:ring-0 mt-2"
                          value={form.topic}
                          onChange={(e) =>
                            setForm({ ...form, topic: e.target.value })
                          }
                        />
                      </div>

                      <div className="mb-5">
                        <label htmlFor="comments" className="font-semibold">
                          İçerik:
                        </label>
                        <textarea
                          name="message"
                          id="comments"
                          className="form-input w-full py-2 px-3 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-amber-400 dark:border-gray-800 dark:focus:border-amber-400 focus:ring-0 mt-2 h-40"
                          value={form.message}
                          onChange={(e) =>
                            setForm({ ...form, message: e.target.value })
                          }
                        ></textarea>
                      </div>
                    </div>
                    <button
                      disabled={loading}
                      type="submit"
                      id="submit"
                      name="send"
                      className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-amber-400 hover:bg-amber-500 border-amber-400 hover:border-amber-500 text-white rounded-md"
                    >
                      Gönder
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container lg:mt-24 mt-16">
          <div className="grid grid-cols-1 lg:grid-cols-2 md:grid-cols-2 gap-[30px]">
            <div className="text-center px-6">
              <div className="relative overflow-hidden text-transparent -m-3">
                <FiHexagon className="h-24 w-24 fill-amber-400/5 group-hover:fill-white/10 mx-auto" />
                <div className="absolute top-2/4 -translate-y-2/4 start-0 end-0 mx-auto text-amber-400 rounded-xl group-hover:text-white duration-500 text-2xl flex align-middle justify-center items-center">
                  <FiMail />
                </div>
              </div>

              <div className="content mt-7">
                <h5 className="title h5 text-lg font-semibold">E-Mail</h5>
                <p className="text-slate-400 mt-3">
                  Bizimle iletişime geçmek için <br /> lütfen aşağıdaki e-posta
                  adresini kullanın.
                </p>

                <div className="mt-5">
                  <Link
                    to="mailto:omerbayramcavus@gmail.com"
                    className="hover:text-amber-400"
                  >
                    omerbayramcavus@gmail.com
                  </Link>
                </div>
              </div>
            </div>

            <div className="text-center px-6">
              <div className="relative overflow-hidden text-transparent -m-3">
                <FiHexagon className="h-24 w-24 fill-amber-400/5 group-hover:fill-white/10 mx-auto" />
                <div className="absolute top-2/4 -translate-y-2/4 start-0 end-0 mx-auto text-amber-400 rounded-xl group-hover:text-white duration-500 text-2xl flex align-middle justify-center items-center">
                  <FiMapPin />
                </div>
              </div>

              <div className="content mt-7">
                <h5 className="title h5 text-lg font-semibold">Konum</h5>
                <p className="text-slate-400 mt-3">
                  Eti, Yükseliş Sk. No:5, 06570 <br /> Çankaya/Ankara
                </p>

                <div className="mt-5">
                  <Link
                    to="https://maps.app.goo.gl/JXvCZJs3Yp3LAgX97"
                    data-type="iframe"
                    className="video-play-icon read-more lightbox hover:text-amber-400"
                  >
                    Google Maps üzerinde görüntüle
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
