import React from "react";
import { Link } from "react-router-dom";
import logo from "../assets/images/logo-light.webp";

export default function Signup() {
  return (
    <>
      <section className="relative overflow-hidden h-screen flex items-center bg-page bg-no-repeat bg-left bg-cover bg-fixed">
        <div className="absolute inset-0 bg-slate-950/20"></div>
        <div className="container relative">
          <div className="md:flex justify-center">
            <div className="lg:w-2/3 md:w-2/4">
              <div className="rounded-lg shadow bg-white dark:bg-slate-900 p-12">
                <Link to="/">
                  <img src={logo} alt="" className="inline-block h-16" />
                  <span className="ml-7 text-xl font-semibold">
                    Hesap Oluştur
                  </span>
                </Link>

                <form className="text-start mt-4">
                  <div className="grid grid-cols-1">
                    <div className="mb-4">
                      <label className="font-semibold" htmlFor="RegisterName">
                        İsim
                      </label>
                      <input
                        id="RegisterName"
                        type="text"
                        className="form-input mt-3 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-amber-400 dark:border-gray-800 dark:focus:border-amber-400 focus:ring-0"
                      />
                    </div>

                    <div className="mb-4">
                      <label className="font-semibold" htmlFor="LoginEmail">
                        E-Mail Adresi
                      </label>
                      <input
                        id="LoginEmail"
                        type="email"
                        className="form-input mt-3 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-amber-400 dark:border-gray-800 dark:focus:border-amber-400 focus:ring-0"
                        placeholder="name@company.com"
                      />
                    </div>

                    <div className="mb-4">
                      <label className="font-semibold" htmlFor="LoginPassword">
                        Şifre
                      </label>
                      <input
                        id="LoginPassword"
                        type="password"
                        className="form-input mt-3 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-amber-400 dark:border-gray-800 dark:focus:border-amber-400 focus:ring-0"
                      />
                    </div>

                    <div className="mb-4">
                      <div className="flex items-center w-full mb-0">
                        <input
                          className="form-checkbox rounded border-gray-200 dark:border-gray-800 text-amber-400 focus:border-amber-300 focus:ring focus:ring-offset-0 focus:ring-amber-200 focus:ring-opacity-50 me-2 cursor-pointer"
                          type="checkbox"
                          value=""
                          id="AcceptT&C"
                        />
                        <label
                          className="form-check-label text-slate-400 cursor-pointer"
                          htmlFor="AcceptT&C"
                        >
                          <Link to="" className="text-amber-400">
                            Şartlar ve Koşulları
                          </Link>{" "}
                          Kabul Ediyorum.
                        </label>
                      </div>
                    </div>

                    <div className="mb-4">
                      <input
                        type="submit"
                        className="py-2 px-5 inline-block tracking-wide border align-middle duration-500 text-base text-center bg-amber-400 hover:bg-amber-500 border-amber-400 hover:border-amberbg-amber-500 text-white rounded-md w-full"
                        value="Kayıt Ol"
                      />
                    </div>

                    <div className="text-center">
                      <span className="text-slate-400 me-2">
                        Hesabınız var mı?{" "}
                      </span>{" "}
                      <Link
                        to="/login"
                        className="text-slate-900 dark:text-white font-bold inline-block"
                      >
                        Giriş Yapın
                      </Link>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
