import React from "react";

export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
    const [user, setUser] = React.useState(null);
    const [loading, setLoading] = React.useState(true);
    
    React.useEffect(() => {
        const user = localStorage.getItem("user");
        if (user) {
        setUser(JSON.parse(user));
        }
        setLoading(false);
    }, []);
    
    const login = (user,remember) => {
        setUser(user);
        if(remember){
            localStorage.setItem("user", JSON.stringify(user));
        }
    }
    
    const logout = () => {
        setUser(null);
        localStorage.removeItem("user");
    };
    
    return (
        <AuthContext.Provider value={{ user, login, logout, loading }}>
        {children}
        </AuthContext.Provider>
    );
    }

export const useAuth = () => React.useContext(AuthContext);