import React from "react";
import { Link } from "react-router-dom";

import Navbar from "../components/navbar";
import BannerImg from "../assets/images/banner4.png";
import Footer from "../components/footer";
import Faq from "../components/Faq";
import { TypeAnimation } from "react-type-animation";
import { useAuth } from "../AuthContext";
import axios from "axios";
import { BsFillArrowUpRightCircleFill } from "react-icons/bs";

export default function Index() {
  const [chatUrl, setChatUrl] = React.useState("/login");
  const { user, logout } = useAuth();

  React.useEffect(() => {
    if (user) {
      const user_url = "https://api-turkgpt.onrender.com";

      axios
        .post(user_url + "/check_token?token=" + user.token)
        .then((res) => {
          if (res.data.status === "error") {
            logout();
          }
        })
        .catch((err) => {
          logout();
        });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  React.useEffect(() => {
    if (user) {
      const user_url = "https://api-turkgpt.onrender.com";

      axios
        .post(user_url + "/chat_url?token=" + user.token)
        .then((res) => {
          setChatUrl(res.data.url);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [user]);

  const workData = [
    {
      icon: "mdi mdi-account-search-outline",
      title: "Şirketinize Özel ChatBot",
      desc: "Turk-GPT ile şirketinizin çalışanlarına hızlı ve etkili bir şekilde destek sağlayın.",
    },
    {
      icon: "mdi mdi-wallet-outline",
      title: "Şirket Verileri ile Çalışma",
      desc: "Turk-GPT, şirketinizin verileri ile çalışarak daha etkili ve hızlı bir şekilde destek sağlar.",
    },
    {
      icon: "mdi mdi-home-plus-outline",
      title: "Güvenli",
      desc: "Turk-GPT, şirketinizin verilerini güvenli bir şekilde işler ve saklar. Verilerinizin güvenliği bizim için önemlidir.",
    },
  ];
  return (
    <>
      <Navbar />
      <section className="relative table w-full lg:py-40 md:py-36 pt-36 pb-24 overflow-hidden bg-white dark:bg-slate-900">
        <div className="container relative z-1">
          <div className="relative grid lg:grid-cols-12 grid-cols-1 items-center mt-10 gap-[30px]">
            <div className="lg:col-span-7">
              <div className="lg:me-1 lg:text-start text-center">
                <h4 className="font-bold lg:leading-normal leading-normal text-4xl lg:text-6xl mb-5">
                  Turk-GPT
                </h4>
                <h1 className="font-bold lg:leading-normal leading-normal text-4xl lg:text-6xl mb-5">
                  <TypeAnimation
                    sequence={["Şirketiniz", 1000, "Takımınız", 1000]}
                    wrapper="span"
                    speed={10}
                    className="typewrite bg-gradient-to-br from-amber-400 to-fuchsia-600 text-transparent bg-clip-text"
                    repeat={Infinity}
                  />
                  için ChatBot
                </h1>
                <p className="text-lg max-w-xl lg:ms-0 mx-auto">
                  Şirketiniz için en iyi chatbot hizmeti. Turk-GPT ile
                  şirketinizin çalışanlarına hızlı ve etkili bir şekilde destek
                  sağlayın.
                </p>
              </div>
              <div className="mt-6 flex justify-left space-x-4 max-lg:justify-center">
                <Link
                  to={chatUrl}
                  className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-amber-400 hover:bg-amber-500 border-amber-400 hover:border-amber-500 text-white rounded-md"
                >
                  {user ? "ChatBot'a Git" : "Şimdi Dene" }
                </Link>
                <Link
                  to="https://huggingface.co/turkgpt"
                  className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-amber-500 hover:bg-amber-400 border-amber-400 hover:border-amber-500 text-white rounded-md"
                  target="_blank"
                >
                  <div className="flex items-center space-x-2">
                    <span>Modeli İncele</span>
                    <BsFillArrowUpRightCircleFill />
                  </div>
                </Link>
              </div>
            </div>

            <div className="lg:col-span-5 max-lg:hidden">
              <div className="relative after:content-[''] after:absolute lg:after:-top-0 after:-top-10 after:-right-32 after:w-[36rem] after:h-[36rem] after:border-2 after:border-dashed after:border-slate-700/10 dark:after:border-slate-200/10 after:rounded-full after:animate-[spin_120s_linear_infinite] after:-z-1 before:content-[''] before:absolute lg:before:-top-24 before:-top-36 before:-right-56 before:w-[48rem] before:h-[48rem] before:border-2 before:border-dashed before:border-slate-700/10 dark:before:border-slate-200/10 before:rounded-full before:animate-[spin_240s_linear_infinite] before:-z-1">
                <div className="relative after:content-[''] after:absolute lg:after:-top-24 after:-top-10 after:-right-0 after:w-[42rem] after:h-[42rem] after:bg-gradient-to-tl after:to-amber-400/30  after:from-fuchsia-600/30 dark:after:to-amber-400/50 dark:after:from-fuchsia-600/50 after:blur-[200px] after:rounded-full after:-z-1">
                  <img
                    src={BannerImg}
                    className="lg:max-w-screen-lg lg:ms-14"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="relative pt-2 md:pb-24 pb-16 overflow-hidden">
        <div className="container relative md:mt-24 mt-16">
          <div className="grid md:grid-cols-3 grid-cols-1 mt-6 gap-6">
            {workData.map((item, index) => {
              return (
                <div className="relative p-6" key={index}>
                  <i
                    className={`${item.icon} bg-gradient-to-tl to-amber-400 from-fuchsia-600 text-transparent bg-clip-text text-[45px]`}
                  ></i>

                  <h5 className="text-xl font-semibold my-5">{item.title}</h5>

                  <p className="text-slate-400">{item.desc}</p>
                </div>
              );
            })}
          </div>
        </div>
        <Faq />
      </section>
      <Footer />
    </>
  );
}
