import "./assets/css/tailwind.css";
import "./assets/css/materialdesignicons.min.css";
import { Route, Routes,useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AuthProvider } from "./AuthContext";

import Index from "./pages";
import AboutUs from "./pages/aboutus";
import Login from "./pages/login";
import Signup from "./pages/signup";
import ResetPassword from "./pages/reset-password";
import Terms from "./pages/terms";
import Privacy from "./pages/privacy";
import Error from "./pages/error";
import Contact from "./pages/contact";
import Chat from "./pages/chat";
import { useEffect } from "react";

function App() {

  const location = useLocation()
  useEffect(() => {
    window.scrollTo(0, 0);
  }
  , [location]);

  return (
    <AuthProvider>
      <ToastContainer />
      <Routes>
        <Route path="/" element={<Index />} />
        <Route path="/aboutus" element={<AboutUs />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/error" element={<Error />} />
        <Route path="*" element={<Error />} />
        <Route path="contact" element={<Contact />} />
        <Route path="chat" element={<Chat />} />
      </Routes>
    </AuthProvider>
  );
}

export default App;
