import React from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../assets/images/logo-light.webp";
import axios from "axios";
import { toast } from "react-toastify";
import { useAuth } from "../AuthContext";

export default function Login() {
  const [form, setForm] = React.useState({
    username: "",
    password: "",
    remember: false,
  });
  const navigate = useNavigate();
  const { user, login } = useAuth();

  React.useEffect(() => {
    if (user) {
      navigate("/");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const user_url = "https://api-turkgpt.onrender.com";
    axios
      .post(
        user_url +
          "/login?username=" +
          form.username +
          "&password=" +
          form.password
      )
      .then((res) => {
        toast.success("Giriş Başarılı!");
        login(res.data, form.remember);
        navigate("/");
      })
      .catch((err) => {
        console.log(err);
        toast.error("Kullanıcı adı veya şifre yanlış!");
      });
  };

  return (
    <>
      <section className="relative overflow-hidden h-screen flex items-center bg-page bg-no-repeat bg-left bg-cover bg-fixed">
        <div className="absolute inset-0 bg-slate-950/20"></div>
        <div className="container relative">
          <div className="md:flex justify-center">
            <div className="lg:w-2/3 md:w-2/4">
              <div className="rounded-lg shadow bg-white dark:bg-slate-900 p-12">
                <Link to="/">
                  <img src={logo} alt="" className="inline-block h-16" />
                  <span className="ml-7 text-xl font-semibold">Giriş Yap</span>
                </Link>

                <form className="text-start mt-4" onSubmit={handleSubmit}>
                  <div className="grid grid-cols-1">
                    <div className="mb-4">
                      <label className="font-semibold" htmlFor="LoginEmail">
                        Kullanıcı Adı
                      </label>
                      <input
                        id="LoginEmail"
                        type="text"
                        className="form-input mt-3 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-amber-400 dark:border-gray-800 dark:focus:border-amber-400 focus:ring-0"
                        name="username"
                        value={form.email}
                        onChange={handleChange}
                      />
                    </div>

                    <div className="mb-4">
                      <label className="font-semibold" htmlFor="LoginPassword">
                        Şifre
                      </label>
                      <input
                        id="LoginPassword"
                        type="password"
                        className="form-input mt-3 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-amber-400 dark:border-gray-800 dark:focus:border-amber-400 focus:ring-0"
                        name="password"
                        value={form.password}
                        onChange={handleChange}
                      />
                    </div>

                    <div className="flex justify-between mb-4">
                      <div className="flex items-center mb-0">
                        <input
                          className="form-checkbox rounded border-gray-200 dark:border-gray-800 text-amber-400 focus:border-amber-300 focus:ring focus:ring-offset-0 focus:ring-amber-200 focus:ring-opacity-50 cursor-pointer me-2"
                          type="checkbox"
                          value=""
                          id="RememberMe"
                          name="remember"
                          onChange={(e) =>
                            setForm({ ...form, remember: e.target.checked })
                          }
                        />
                        <label
                          className="form-checkbox-label text-slate-400 cursor-pointer"
                          htmlFor="RememberMe"
                        >
                          Beni Anımsa
                        </label>
                      </div>
                      <p className="text-slate-400 mb-0">
                        <Link to="/reset-password" className="text-slate-400">
                          Şifrenizi mi Unuttunuz?
                        </Link>
                      </p>
                    </div>

                    <div className="mb-4">
                      <input
                        type="submit"
                        className="py-2 px-5 cursor-pointer inline-block tracking-wide border align-middle duration-500 text-base text-center bg-amber-400 hover:bg-amber-500 border-amber-400 hover:border-amberbg-amber-500 text-white rounded-md w-full"
                        value="Giriş Yap"
                      />
                    </div>

                    <div className="text-center">
                      <span className="text-slate-400 me-2">
                        Hesabınız Yok Mu?
                      </span>{" "}
                      <Link
                        to="/signup"
                        className="text-slate-900 dark:text-white font-bold inline-block"
                      >
                        Kaydol
                      </Link>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
